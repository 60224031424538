.categorias {
    margin-top: 16px;
}

.categorias:hover {
    cursor: grab;
}

.categoryButton {
    width: auto !important;
    height: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 10px;
    margin-bottom: 16px;
    background-color: rgba(255,255,255,1);
    color: rgba(75,85,99,1);
    font-weight: 500;
    border-radius: 50px;
    border: 1px solid #e5e7eb;
    display: flex;
    justify-content: center;
    align-items: center;
}
.categoryButton:hover {
    color: rgba(55,65,81,1);
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
}

.categoryButton.oscuro {
    background-color: black;
    color: white;
    border: 1px solid black;
}

.categoryButton.oscuro:hover {
    border: 1px solid white;
}

.active,
.active:hover {
    background-color: rgba(17,24,39,1);
    color: white;
}

.active.oscuro,
.active.oscuro:hover {
    background-color: white;
    color: black;
}