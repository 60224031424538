.home-page {
    width: 100%;
    min-height: 100vh;
    background-color: #ff1a8d;
    background-attachment: fixed;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.home-page .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-page .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}

.home-page .row img {
    width: 250px;
    height: 100px;
    margin-bottom: 30px;
}

.home-page-title {
    font-size: 20px;
    padding-bottom: 8px;
    padding-left: 4px;
    margin: 0;
    text-align: center;
    font-weight: bold;
    margin-bottom: 50px;
}

.home-page button {
    font-weight: bold;
    background-color: white;
    color: #ff1a8d;
    border: 1px solid white;
    border-radius: 15px;
    height: 50px;
    margin-top: 10px;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.home-page-button-img {
    position: absolute;
    left: 30px;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
}

.home-page-button-img img {
    width: 30px !important;
    height: 30px !important;
    margin: 0 !important;
}

.home-page button:hover {
    background-color: #ff1a8d;
    color: white;
    box-shadow: 0px 4px 4px #e663a4;
}

@media (max-width: 480px) {
    .home-page .row {
        width: 100%;
    }
}