.navbar {
    height: 80px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    display: flex;
    align-items: center;
}

.navbar.oscuro {
    background-color: black;
}

.navbar img {
    height: 100%;
}

.navbar-controls {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.navbar-controls-username span {
    font-weight: bold;
}

.navbar-controls.oscuro {
    color: white;
}

.navbar-icono {
    height: 40px;
    width: 40px;
    border: 1px solid black;
    cursor: pointer;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.5s;
}

.navbar-icono.oscuro {
    border: 1px solid white;
}

.navbar-icono:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.navbar-icono.oscuro:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.navbar-icono.navbar-active {
    background-color: black;
}

.navbar-icono.oscuro.navbar-active {
    background-color: white;
}

.navbar-icono-user {
    height: 100%;
    filter: none;
    transition: all 0.5s;
}

.navbar-icono-user.navbar-active {
    filter: invert(1);
}

.navbar-icono-user.oscuro {
    filter: invert(1);
}

.navbar-icono-user.oscuro.navbar-active {
    filter: none;
}

.navbar-expand {
    overflow: hidden;
    height: 0;
    will-change: height;
    width: 150px;
    border-radius: 0.5rem;
    border: none;
    transform: translate3d(0px, 34px, 0px);
    position: absolute;
    top: 10px;
    right: 0px;
    text-align: center;
    color: #fff;
    transition: all 0.2s;
    color: black;
    background-color: white;
    z-index: 1000;
}

.navbar-expand.navbar-active {
    height: auto;
    box-shadow: 0 1px 4px 3px rgba(25,29,37,.101961);
    padding: 10px 0;
}

.navbar-expand div {
    padding: 5px 0;
}

.navbar-expand div:hover {
    cursor: pointer;
    background-color: #f2f4f7;
}

#navbar-expand-username {
    display: none;
    margin-bottom: 15px;
    padding: 5px 0 15px 0;
    border-bottom: 1px solid #e5e7eb;
}

#navbar-expand-username:hover {
    cursor: default;
    background-color: white;
}

#navbar-expand-username span {
    font-weight: bold;
}

/* @media screen and (max-width: 414px) {
    .navbar img {
        height: auto;
        width: 100%;
    }
} */

@media (max-width: 575px) {
    #navbar-expand-username {
        display: block;
    }

    .navbar-controls-username {
        display: none;
    }
}