.item-box {
    padding: 0 12px;
}

.item {
    height: 100%;
    padding: 12px;
    background-color: rgba(255,255,255,1);
    box-shadow: rgba(181, 129, 108, 0.8) 0px 8px 34px -25px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.item.oscuro {
    background-color: #101010;
    color: white;
    box-shadow: rgba(255, 255, 255, 0.8) 0px 8px 34px -25px;
}

.itemText {
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-family: Segma;
}

/* .itemText {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.itemTextLarge {
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-family: Segma;
}

.itemImg {
    display: flex;
    position: relative;
}

.itemImg-box {
    height: 96px;
    min-width: 96px;
    width: 100%;
    margin-left: 12px;
    border-radius: 6px;
    position: relative;
    left: calc(100% - 108px);
}

.itemImg img {
    width: 96px;
    height: 96px;
    border-radius: 6px;
    object-fit: cover;
    max-width: 100%;
}

/* .itemImg {
    grid-column-start: 3;
    grid-column-end: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemImg img {
    width: 100%;
    height: auto;
    max-height: 120px;
    border-radius: 10px;
} */

.name {
    color: rgb(16, 4, 35);
    font-family: Segma;
    font-size: 17px;
    font-weight: 700;
    max-width: 100%;
    margin-bottom: 4px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
}

.name.oscuro {
    color: white;
}

.description-box {
    font-family: Segma;
    color: rgba(55,65,81,1);
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 4px;
    line-height: 16px;
    -webkit-line-clamp: 2;
    transition: height 0.5s ease;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
}

.description-box.full {
    -webkit-line-clamp: unset;
    transition: height 0.5s ease;
}

.description {
    color: rgba(55,65,81,1);
    font-family: Segma;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

.description.oscuro {
    color: white;
}

.leer {
    color: rgb(16, 4, 35);
    line-height: 16px;
    font-family: Segma;
    font-size: 13px;
    font-weight: 700;
    margin-top: 4px;
    width: max-content;
}

.leer.oscuro {
    color: white;
}

.btnComplete {
    margin-top: 5px;
    font-size: 16px;
    color: rgb(45, 52, 65);
    font-weight: bolder;
    cursor: pointer;
}

.btnComplete.oscuro {
    color: white;
}

.precio-box {
    color: rgb(16, 4, 35);
    font-family: Segma;
    font-size: 16px;
    font-weight: 700;
    margin-top: 8px;
    display: inline-block;
    padding-top: 14px;
    line-height: 1.25;
}

.precio {
    white-space: nowrap;
    font-family: Segma;
}

.precio.oscuro {
    color: white;
}

.precio.precio-sinstock {
    color: red;
}

.link {
    width: 100%;
    height: 100%;
    text-decoration: none;
}

.item li {
    list-style: none;
}

.verMas {
    animation: abrir 0.5s forwards;
}

.verMenos {
    animation: cerrar 0.5s forwards;
}

@keyframes abrir {
    0% {
        transform: translateY(-20px);
    }
    
    100% {
        transform: translateY(0px);
    }
}

@keyframes cerrar {
    0% {
        transform: translateY(20px);
    }
    
    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 767px) {
    .item-box {
        padding: 0 !important;
    }
}

/* @media screen and (min-width: 768px) {
    .item:hover,
    .item:active,
    .item:focus {
        background-color: rgba(0,0,0,1);
    }

    .item:hover .name,
    .item:hover .description,
    .item:hover .precio,
    .item:hover .btnComplete {
        color:rgb(255,255,255,1);
    }

    .item:active .name,
    .item:active .description,
    .item:active .precio,
    .item:active .btnComplete {
        color:rgb(255,255,255,1);
    }

    .item:focus .name,
    .item:focus .description,
    .item:focus .precio,
    .item:focus .btnComplete {
        color:rgb(255,255,255,1);
    }
} */