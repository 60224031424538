.name-loader {
    background-color: #f3f3f3;
    max-width: 250px;
    height: 25.5px;
    border-radius: 6px;
    margin-bottom: 4px;
}

.name-loader.oscuro {
    background-color: #2f2d2d;
}

.description-loader {
    background-color: #f3f3f3;
    height: 32px;
    border-radius: 6px;
    margin-top: 8px;
}

.description-loader.oscuro {
    background-color: #2f2d2d;
}

.precio-loader {
    background-color: #f3f3f3;
    width: 80px;
    height: 20px;
    border-radius: 6px;
    padding-top: 14px;
    margin-top: 8px;
}

.precio-loader.oscuro {
    background-color: #2f2d2d;
}

.itemImgLoader-box {
    background-color: #f3f3f3;
    width: 96px;
    height: 96px;
    border-radius: 6px;
    margin-left: 12px;
}

.itemImgLoader-box.oscuro {
    background-color: #2f2d2d;
}