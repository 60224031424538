.list.con-footer{
    margin-bottom: 80px;
}

.list img {
    width: 100%;
    margin-bottom: 16px;
}

.list h3 {
    padding-left: 20px;
    padding-top: 32px;
    text-align: left;
    font-family: Segma;
    font-size: 17px;
    font-weight: bold;
}

.list .h3-oscuro {
    color: white;
}

.list hr {
    width: 100%;
    color: black;
    margin-bottom: 24px;
}

.list .spinnerList {
    display: flex;
    justify-content: center;
    color: rgb(16, 4, 35);
}

.list .spinnerList.oscuro {
    color: white;
}

@media screen and (min-width: 600px) {
    .list img {
        margin: 0;
    }
}